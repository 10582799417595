import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridColumnDataType, IGridCellEventArgs, IRowSelectionEventArgs, IgxColumnComponent, IgxDateFilteringOperand, IgxExpansionPanelComponent, IgxGridComponent, IgxNumberFilteringOperand, IgxSelectComponent, IgxStringFilteringOperand, OverlaySettings, SortingDirection, IgxExpansionPanelHeaderComponent, IgxButtonDirective, IgxRippleDirective, IgxSelectToggleIconDirective, IgxIconComponent, IgxExpansionPanelBodyComponent, IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxCellTemplateDirective, IgxGridFooterComponent, IgxFilterCellTemplateDirective, IgxIconModule, IgxInputGroupModule } from '@infragistics/igniteui-angular';
import { ToggleSwitchComponent } from 'src/app/components/UI/controls/toggle-switch/toggle-switch.component';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { RoleFilterProvider } from 'src/app/util/RoleFilters';
import { Subscription } from 'rxjs';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import { CompareCode } from 'src/app/util/SortCompare';
import { DatalexClient, IUserLimitedBE, IRoleTypeBE, IRoleTypeCategoryBE, IDisciplineBE, ICaseSearchResultBE } from '@datalex-software-as/datalex-client';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { CaseStatusEnum } from 'src/app/util/CaseStatusEnum';
import { DatalexExceptionHandlerService } from 'src/app/services/datalex-exception-handler.service';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { DeviceService } from 'src/app/services/device.service';
import { GridFilterInputComponent } from '../../UI/grid-filter-input/grid-filter-input.component';
import { GridItemCountComponent } from '../../UI/grid-item-count/grid-item-count.component';
import { ToggleSwitchComponent as ToggleSwitchComponent_1 } from '../../UI/controls/toggle-switch/toggle-switch.component';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import newActivity from 'src/app/classes/Activity/Actvity';
import { ActivityLogService } from '../../UI/activity-log-overlay/activity-log.service';


@Component({
  selector: 'app-find-case',
  templateUrl: './find-case.component.html',
  styleUrls: ['./find-case.component.scss'],
  standalone: true,
  imports: [NgIf, IgxExpansionPanelComponent, IgxExpansionPanelHeaderComponent, IgxButtonDirective, IgxRippleDirective, IgxSelectToggleIconDirective, IgxIconComponent, IgxExpansionPanelBodyComponent, IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, ToggleSwitchComponent_1, IgxSimpleComboComponent, IgxGridComponent, IgxColumnComponent, IgxCellTemplateDirective, IgxGridFooterComponent, GridItemCountComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, IgxInputGroupModule, IgxIconModule]
})
export class FindCaseComponent implements OnInit, OnDestroy, AfterViewInit {
  forceUpdateSubscription!: Subscription;

  isMobile!: boolean;
  isTablet!: boolean;

  isTabletPortrait: boolean = false;
  isTabletLandscape: boolean = false;
  isDesktopDevice: boolean = false;

  private subscriptions = new Subscription();

  isOwnCases: boolean = false;

  isLoading: boolean = false;

  constructor(public screen: ScreenDimensionService,
    private dlxEx: DatalexExceptionHandlerService,
    private sys: SystemCacheService,
    private userRights: UserRightsProviderService,
    private dlxClient: DatalexClient,
    private rf: RoleFilterProvider,
    private router: Router,
    private eventEmitter: EventEmitterService,
    public gms: GridMethodsService,
    private route: ActivatedRoute,
    public combo: ComboSelectFunctionsService,
    public screenSize: ScreenSizeService,
    public deviceService: DeviceService,
    private als: ActivityLogService,
  ) {
    this.forceUpdateSubscription = this.eventEmitter.getForceUpdateEvent().subscribe(() => this.forceUpdate());
    this.nameInitials = this.route.snapshot.paramMap.get('name');
    this.isDesktopDevice = this.deviceService.getDeviceType() === 'Desktop';

    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));
    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.deviceService.deviceOrientation$.subscribe(orientation => {
      this.isTabletPortrait = this.deviceService.getDeviceType() === 'Tablet' && orientation === 'portrait';
      this.isTabletLandscape = this.deviceService.getDeviceType() === 'Tablet' && orientation === 'landscape';

    }));

    if (this.checkIfOwnCases()) {
      this.isOwnCases = true;
      this.caseHandlerIdOrCaseResponsibleId = this.sys.loggedinUserContact?.Id ?? null;
    }
  }

  @ViewChild(IgxExpansionPanelComponent, { read: IgxExpansionPanelComponent })
  public panel!: IgxExpansionPanelComponent;

  public handleExpansion(evt?: { event: Event }) {
    this.headerTitle = "Skjul"
  }

  public handleCollapse(evt?: { event: Event }) {
    this.headerTitle = "Nytt Søk"
  }

  headerTitle = "Skjul";


  cases: ICaseSearchResultBE[] = [];
  filteredDatasource!: ICaseSearchResultBE[];


  caseNumber: number | null = null;
  caseTitle: string | null = null;
  caseHandlerIdOrCaseResponsibleId: string | null = null;
  dicipline: string | null = null;
  reference: string | null = null;
  roleTypeCategoryId: string | null = null;
  roleTypeId: string | null = null;
  nameInitials: string | null = null;
  searchOrOnTitle: boolean = true;

  active: boolean = true;
  restricted: boolean = true;
  closed: boolean = false;
  internal: boolean = false;

  fuzzySearch: boolean = false;


  roleCategories!: IRoleTypeCategoryBE[] | null;
  roles: IRoleTypeBE[] = [];
  diciplines!: IDisciplineBE[] | null;
  employees!: IUserLimitedBE[] | null;

  rolesReadOnly: boolean = true;

  labels: string[] = ["ELLER", "OG"]

  canSearch: boolean = true;

  emptyGridMessage: string = "Søkeresultatet vil vises her"
  grid_message: string = "Søkeresultatet vil vises her"


  ngOnInit(): void {
    // this.sys.executeWhenReady(this.initComponent);
    try {
      this.initComponent();
    }
    catch {
      this.sys.isReady.subscribe({
        next: () => {
          this.initComponent();
        }
      })
    }
  }

  public forceUpdate() {
    this.initComponent();
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    removeEventListener("keyup", this.onEnterPress);
    this.subscriptions.unsubscribe();
  }

  @ViewChild('caseSearchGrid', { read: IgxGridComponent, static: false })
  public grid!: IgxGridComponent;

  @ViewChild(ToggleSwitchComponent) $toggleSwitch!: ToggleSwitchComponent;



  @ViewChild('rkFilterInput') rkFilterInput!: HTMLInputElement
  @ViewChild('roleFilterInput') roleFilterInput!: HTMLInputElement;
  @ViewChild('discFilterInput') discFilterInput!: HTMLInputElement;

  /*  */
  public singleBranchExpand = false;
  onRoleCategoryReset(event: any) {

    this.roles = [];
    this.roleTypeId = "";
  }

  initComponent() {
    this.roleCategories = this.sys.roleTypeCatergorys.sort(CompareCode);
    this.employees = this.sys.allEmployees.sort(CompareCode);
    this.diciplines = this.sys.discipline.sort(CompareCode);

    addEventListener("keyup", this.onEnterPress);
  }

  toggleSwitch(status: boolean) {
    this.searchOrOnTitle = status;
  }

  onOpen(input: string) {
    setTimeout(() => {
      //@ts-ignore
      this[input].focus();

    }, 100);


  }



  onCategoryChange(event: any) {
    if (event) {

      // this.roles = this.rf.filterRoleTypesByCategoryId(this.sys.roleTypes, event.newSelection.value).sort(CompareCode);
      this.roles = this.rf.filterRoleTypesByCategoryId(this.sys.roleTypes, event).sort(CompareCode);
      this.roleTypeId = "";
    }
  }

  onMobileReset() {


    this.caseNumber = null;
    this.caseTitle = null;
    this.nameInitials = null;

  }

  onReset() {
    this.caseNumber = null;
    this.caseTitle = null;
    this.caseHandlerIdOrCaseResponsibleId = null;
    this.dicipline = null;
    this.reference = null;
    this.roleTypeCategoryId = null;
    this.roleTypeId = null;
    this.nameInitials = null;
    this.searchOrOnTitle = true;
    this.active = true;
    this.restricted = true;
    this.closed = false;
    this.internal = false;
    this.fuzzySearch = false;
    this.roles = [];

    // this.roleCategories = undefined;
    // this.diciplines = undefined;
    // this.employees = undefined;

    // if (this.$roleCategories) {
    //   this.$roleCategories.reset();
    // }
    // if (this.$roles) {
    //   this.$roles.reset();
    // }
    // if (this.$dicipline) {
    //   this.$dicipline.reset();
    // }
    // if (this.$employees) {
    //   this.$employees.reset();
    // }

    this.forceUpdate()


    this.$toggleSwitch.forceChange(false);
  }

  onEnterPress(event: any) {
    this.isLoading = true;
    const btn = <HTMLElement>document.getElementById("submit");
    if (event.keyCode === 13) {
      event.preventDefault();
      if (btn !== null) {
        btn.click()
      }
    }
  }

  checkIfOwnCases() {
    return this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId.toUpperCase() === UserRightTypeEnum.OWN_CASES;
  }

  onSearch() {
    this.isLoading = true;
    const a = {
      caseNumber: this.caseNumber,
      caseTitle: this.emptyStringToNull(this.caseTitle),
      caseHandlerIdOrCaseResponsibleId: this.emptyStringToNull(this.caseHandlerIdOrCaseResponsibleId),
      dicipline: this.emptyStringToNull(this.dicipline),
      reference: this.emptyStringToNull(this.reference),
      roleTypeCategoryId: this.emptyStringToNull(this.roleTypeCategoryId),
      roleTypeId: this.emptyStringToNull(this.roleTypeId),
      nameInitials: this.emptyStringToNull(this.nameInitials),
      searchOrOnTitle: this.searchOrOnTitle,
      searchActiveCases: this.active,
      searchRestricted: this.restricted,
      searchClosed: this.closed,
      searchInternal: this.internal,
      fuzzySearchContact: this.fuzzySearch
    }

    this.dlxClient.FindCasesNew(
      a.caseNumber,
      a.caseTitle!,
      a.caseHandlerIdOrCaseResponsibleId,
      a.dicipline!,
      a.reference!,
      a.roleTypeCategoryId,
      a.roleTypeId,
      a.nameInitials!,
      a.searchOrOnTitle,
      a.searchActiveCases,
      a.searchRestricted,
      a.searchClosed,
      a.searchInternal,
      a.fuzzySearchContact).subscribe({
        next: (response) => {

          if (this.screen.width > 1024) {
            this.grid.sort([
              { fieldName: 'ContactName', dir: SortingDirection.Asc, ignoreCase: true }
            ]);
          }

          this.cases = this.filteredDatasource = response.filter(i => i.Title.toLowerCase() !== "adgangskontroll");
          this.grid_message = "Slutten av listen";
          this.canSearch = false;

          if (this.cases.length === 0) {
            this.grid_message = this.emptyGridMessage = "Fant ingen saker, juster søket og prøv igjen";
          }

          if (this.cases.length === 1) {
            if (this.checkIfOwnCases()) return;

            this.router.navigate(['/case', this.cases[0].CaseId]);
          }
          this.isLoading = false;
        },
        error: (error) => {
          this.dlxEx.exception.bind(this);
          this.isLoading = false;
        }
      })
  }

  emptyStringToNull(string: string | null) {
    if (string === "") return null
    return string
  }

  mobileClick(caseId: string) {

    this.router.navigate(['/case', caseId]);
  }

  onNewSearch() {
    this.canSearch = true;
    this.onMobileReset();
    this.cases = [];
  }

  handleRowSelection(event: any): void {
    const { added } = event;

    this.router.navigate(['/case', event.added[0].CaseId]);
  }

  public onCellClick(event: IGridCellEventArgs) {
    if (this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId === UserRightTypeEnum.NONE) {
      const activity = newActivity({
        message: `Ingen tilgang saksbehandling, kontakt din administrator.`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      alert("Ingen tilgang saksbehandling, kontakt din administrator.")
      return
    }

    if (this.userRights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessId === UserRightTypeEnum.OWN_CASES) {
      this.dlxClient.GetCaseLimited(event.cell.row.data.CaseId).subscribe({
        next: (response) => {
          if (this.userRights.checkCaseAccessOwnCases({ sa: response.CaseResponsibleId!, sb: response.CaseHandlerId }, this.sys.loggedinUserContact.Id)) {
            this.router.navigate(['/case', response.Id]);
          } else {
            const activity = newActivity({
              message: `Ingen tilgang, du har kun tilgang til egne saker.`,
              type: "failure",
              timestamp: new Date()
            })

            this.als.appendActivity(activity)
            alert("Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.")
            return
          }
        }
      })

      return
    }
    this.router.navigate(['/case', event.cell.row.data.CaseId]);
  }

  rowSelection(event: IRowSelectionEventArgs) {
    if (!this.isDesktopDevice) {
      this.router.navigate(['/case', event.newSelection[0].CaseId])
    }
  }

  getCaseStatusText(caseStatusId: string): { icon: string, color: string } {
    switch (caseStatusId.toLowerCase()) {
      case CaseStatusEnum.Aktiv.toLowerCase():
        return { icon: "Ak", color: "color: green" }

      case CaseStatusEnum.Sperret.toLowerCase():
        return { icon: "S", color: "color: orange" }

      case CaseStatusEnum.Avsluttet.toLowerCase():

        return { icon: "Av", color: "color: grey" }
      default:
        return { icon: "?", color: "color: grey" };
    }

  }

  tabletFilter: string = "";
  tabletFilterChange() {
    try {
      this.filteredDatasource = this.cases.filter(item => {
        const { ContactName, RoleCode, CaseClient, CaseAdversary, Title, CaseResponsible, CaseHandler, CaseNumber } = item;
        if (!isNaN(parseInt(this.tabletFilter))) {
          return String(CaseNumber).includes(this.tabletFilter);
        }

        return ContactName?.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || RoleCode?.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || CaseClient?.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || (CaseAdversary ?? "").toUpperCase().includes(this.tabletFilter.toUpperCase())
          || Title?.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || CaseResponsible?.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || (CaseHandler ?? "").toUpperCase().includes(this.tabletFilter.toUpperCase())
      })

      if (this.filteredDatasource.length > 0) {
        this.grid_message = "Slutten av listen";
      } else {
        this.grid_message = "Ingen saker å vise";
      }

    } catch (err: any) {

    }

  }

  private _filterValues = new Map<IgxColumnComponent, any>();
  //GRID FUNCTIONS


  onRightClick(event: IGridCellEventArgs) {
    event.event.preventDefault();
  }
  public formatDate(val: Date) {
    return new Intl.DateTimeFormat('en-US').format(val);
  }

  public formatCurrency(val: string) {
    return parseInt(val, 10).toFixed(2);
  }

  public getFilterValue(column: IgxColumnComponent): any {
    return this._filterValues.has(column) ? this._filterValues.get(column) : null;
  }

  public onKeyDown(event: KeyboardEvent) {
    event.stopImmediatePropagation();
  }

  public onInput(input: any, column: IgxColumnComponent) {
    this._filterValues.set(column, input.value);

    if (input.value === '') {
      this.grid.clearFilter(column.field);
      return;
    }

    let operand = null;
    switch (column.dataType) {
      case GridColumnDataType.Number:
        operand = IgxNumberFilteringOperand.instance().condition('equals');
        break;
      default:
        operand = IgxStringFilteringOperand.instance().condition('contains');
    }
    this.grid.filter(column.field, this.transformValue(input.value, column), operand, column.filteringIgnoreCase);
  }

  public clearInput(column: IgxColumnComponent) {
    this._filterValues.delete(column);
    this.grid.clearFilter(column.field);
  }

  public onClick(event: any) {
    const { target } = event;
    if (!target.isFocused) {
      target.focus();

    }
  }

  public onDateSelected(event: any, column: IgxColumnComponent) {
    this._filterValues.set(column, event);

    this.grid.filter(column.field, event, IgxDateFilteringOperand.instance().condition('equals'),
      column.filteringIgnoreCase);
  }

  public openDatePicker(openDialog: () => void) {
    openDialog();
  }

  private transformValue(value: any, column: IgxColumnComponent): any {
    if (column.dataType === GridColumnDataType.Number) {
      value = parseFloat(value);
    }

    return value;
  }


  log(arg: any) {

  }

  selectionChanging(e: any) {


  }

  showMore(e: any) {


  }

  togglePannel() {
    this.panel.toggle();


  }


  get isAnyFindCaseInputFilled(): boolean {
    return !!(this.caseNumber || this.nameInitials || this.caseTitle || this.reference || this.roleTypeCategoryId || this.roleTypeId || this.dicipline || this.caseHandlerIdOrCaseResponsibleId);
  }

}
