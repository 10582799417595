<div class="flex-row component-structure case-searchbar section-div">
  <igx-expansion-panel #panel *ngIf="screen.width <= 1024" [collapsed]=false (contentExpanded)="handleExpansion($event)"
    (contentCollapsed)="handleCollapse($event)">
    <igx-expansion-panel-header class="panel-header" [iconPosition]="'none'">
      <button type="button" igxButton="raised" [style.background]="'#F5F5F5'" igxRipple="white"
        igxButton>{{headerTitle}}</button>
      <ng-template igxSelectToggleIcon let-collapsed>
        <igx-icon>{{ collapsed ? 'add_circle' : 'add_circle_outline'}}</igx-icon>
      </ng-template>
    </igx-expansion-panel-header>
    <igx-expansion-panel-body class="panel-body">
      <ng-container *ngIf="screen.width <= 1024; then SearchBox"></ng-container>
    </igx-expansion-panel-body>
  </igx-expansion-panel>

  <ng-container *ngIf="screen.width > 1024; then SearchBox"></ng-container>
  <ng-template #SearchBox>
    <div class="input-section">
      <div class="div1">
        <igx-input-group displayDensity="compact" type="border">
          <input #caseNumberInput igxInput name="caseNumber" type="text" [(ngModel)]="caseNumber" />
          <label igxLabel for="caseNumber">Saksnummer</label>
          @if(caseNumberInput.value.length > 0){

          <igx-icon igxSuffix (click)="caseNumber = null">close</igx-icon>
          }
        </igx-input-group>
      </div>

      <div class="div2">
        <igx-input-group displayDensity="compact" type="border">
          <input #nameInput igxInput name="nameInitials" type="text" [(ngModel)]="nameInitials" />
          <label igxLabel for="nameInitials">Navn / Initialer</label>
          @if(nameInput.value.length > 0){
          <igx-icon igxSuffix (click)="nameInitials = null">close</igx-icon>
          }
        </igx-input-group>
      </div>

      <div class="div3">
        <app-toggle-switch [labels]="labels" (toggleStatus)="toggleSwitch($event)"></app-toggle-switch>
      </div>

      <div class="div4">
        <igx-input-group displayDensity="compact" type="border">
          <input #titleInput igxInput name="caseTitle" type="text" [(ngModel)]="caseTitle" />
          <label igxLabel for="caseTitle">Tittel / Beskrivelse</label>
          @if(titleInput.value.length > 0){
          <igx-icon igxSuffix (click)="caseTitle = null">close</igx-icon>
          }
        </igx-input-group>
      </div>

      <div class="div5">
        <igx-input-group displayDensity="compact" type="border">
          <input #refInput igxInput name="reference" type="text" [(ngModel)]="reference" />
          <label igxLabel for="reference">Sak / Kontakt referanse</label>
          @if(refInput.value.length > 0){
          <igx-icon igxSuffix (click)="reference = null">close</igx-icon>
          }
        </igx-input-group>
      </div>

      <div class=" div6 simple-select-wrapper">
        <label #roleCategoryLabel igxLabel
          class="simple-select-label simple-select-label--selected">RolleKategori</label>
        <igx-simple-combo #RoleCategories [data]="roleCategories" displayDensity="compact"
          (opening)="combo.comboOpening(roleCategoryLabel)"
          (closing)="combo.comboClosing(roleCategoryLabel, roleTypeCategoryId)" [displayKey]="'Name'" [valueKey]="'Id'"
          [(ngModel)]="roleTypeCategoryId" [type]="'border'" (ngModelChange)="onCategoryChange($event)">
        </igx-simple-combo>
      </div>


      <div class="div7  simple-select-wrapper phone-select">
        <label #roleLabel igxLabel class="simple-select-label simple-select-label--selected">{{!(roles.length > 0) ?
          'Velg RolleKategori' : 'Rolle'}}</label>
        <igx-simple-combo #Roles [data]="roles" displayDensity="compact" (opening)="combo.comboOpening(roleLabel)"
          (closing)="combo.comboClosing(roleLabel, roleTypeId)" [displayKey]="'Name'" [valueKey]="'Id'"
          [(ngModel)]="roleTypeId" [type]="'border'" [disabled]="!(roles.length > 0)">
        </igx-simple-combo>
      </div>

      <div class="div8 simple-select-wrapper">
        <label #diciplinesLabel igxLabel class="simple-select-label simple-select-label--selected">Fagområde</label>
        <igx-simple-combo #Diciplines class="simple-select-disable-reset" [data]="diciplines" displayDensity="compact"
          (opening)="combo.comboOpening(diciplinesLabel)" (closing)="combo.comboClosing(diciplinesLabel, dicipline)"
          [displayKey]="'Name'" [valueKey]="'Code'" [(ngModel)]="dicipline" [type]="'border'">
        </igx-simple-combo>
      </div>

      <div class="div9 simple-select-wrapper phone-select">
        <label #responsibleLabel igxLabel class="simple-select-label simple-select-label--selected">Ansvarlig /
          behandler</label>
        <igx-simple-combo #Employees id="caseHandlerIdOrCaseResponsibleId" class="simple-select-disable-reset"
          [data]="employees" displayDensity="compact" (opening)="combo.comboOpening(responsibleLabel)"
          (closing)="combo.comboClosing(responsibleLabel, caseHandlerIdOrCaseResponsibleId)"
          [displayKey]="'ContactName'" [valueKey]="'ContactId'" [(ngModel)]="caseHandlerIdOrCaseResponsibleId"
          [type]="'border'" [disabled]="isOwnCases">
        </igx-simple-combo>
      </div>

      <div class="div10 flex-row checkbox-section">
        <div>
          <input type="checkbox" name="active" [(ngModel)]="active">
          <label for="active">Aktiv</label>
        </div>
        <div>
          <input type="checkbox" name="restricted" [(ngModel)]="restricted">
          <label for="restricted">Sperret</label>
        </div>
        <div>
          <input type="checkbox" name="closed" [(ngModel)]="closed">
          <label for="closed">Avsluttet</label>
        </div>
        <div>
          <input type="checkbox" name="internal" [(ngModel)]="internal">
          <label for="internal">Interne</label>
        </div>
      </div>
      <button class=" div11 find-case-button" id="submit" igxButton="raised" igxButtonColor="white"
        [style.background]="'#AEC965'" igxRipple="white" (click)="onSearch()"
        [style.background]="isAnyFindCaseInputFilled ? '#AEC965' : '#BCBCBC'"
        [disabled]="!isAnyFindCaseInputFilled">SØK</button>
      <button class=" div12 find-case-button" igxButton="raised" igxButtonColor="white" [style.background]="'#575757'"
        igxRipple="white" (click)="onReset()" [disabled]="!isAnyFindCaseInputFilled">NULLSTILL</button>
    </div>
  </ng-template>
</div>

<igx-grid class="grid" igxPreventDocumentScroll #caseSearchGrid [data]="cases"
  [displayDensity]="deviceService.gridDensity" [autoGenerate]="false" width="100%" [emptyGridMessage]="emptyGridMessage"
  emptyFilteredGridMessage="Filtrering gir ingen treff" [cellSelection]="'single'" [rowSelection]="'single'"
  (rowSelectionChanging)="rowSelection($event)" (contextMenu)="onRightClick($event)" (doubleClick)="onCellClick($event)"
  [hideRowSelectors]="true" sortable="true" [allowFiltering]=" screen.width > 475 ? true : false"
  [isLoading]="isLoading">
  <igx-column *ngIf="screen.width < 475" field="Klient / Tittel">
    <ng-template #compositeTemp igxCell let-cell="cell">
      <div class="contact-container">
        <span><strong>{{cell.row.data.CaseClient}}</strong></span><br />
        <span>{{cell.row.data.Title}}</span>
        <br />
      </div>
    </ng-template>
  </igx-column>

  <igx-column *ngIf="screen.width > 475" width="70px" field="ContactNumber" header="K.nr" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="screen.width > 475" field="ContactName" header="Navn" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="screen.width > 475" width="60px" field="RoleCode" header="Rolle" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="screen.width > 475 && isDesktopDevice" width="120px" field="CaseReference" header="Kont.Ref."
    [sortable]="true" [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="screen.width > 475" width="15%" field="CaseClient" header="Klient" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="screen.width > 475" width="15%" field="CaseAdversary" header="Motpart" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
  <igx-column *ngIf="screen.width > 475" field="Title" header="Tittel" [dataType]="'string'" [sortable]="true"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="screen.width > 475 && !isTabletPortrait" width="50px" field="CaseStatusTypeId" header="S"
    [filterCellTemplate]="defaultFilterTemplate">
    <ng-template igxCell let-cell="cell">
      <span class="material-icons-outlined"
        [style]="getCaseStatusText(cell.value).color">{{getCaseStatusText(cell.value).icon}}</span>
    </ng-template>
  </igx-column>

  <igx-column width="60px" field="CaseResponsible" header="SA" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate" class="resetCellPadding"></igx-column>

  <igx-column width="60px" field="CaseHandler" header="SB" [sortable]="true" [dataType]="'string'"
    [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="screen.width > 475 && isTabletPortrait" [width]="'70px'" field="CaseNumber" [header]="'Sak' "
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="screen.width > 1024 && isDesktopDevice" width="180px" field="CaseDiscipline" header="Fagområde"
    [sortable]="true" [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="screen.width > 475 && isTabletPortrait" width="50px" field="CaseStatusTypeId" header="S"
    [filterCellTemplate]="defaultFilterTemplate">
    <ng-template igxCell let-cell="cell">
      <span class="material-icons-outlined"
        [style]="getCaseStatusText(cell.value).color">{{getCaseStatusText(cell.value).icon}}</span>
    </ng-template>
  </igx-column>

  <igx-column *ngIf="screen.width > 475" width="50px" field="StartedDate" header="År" [sortable]="true"
    [filterCellTemplate]="defaultFilterTemplate">
    <ng-template igxCell let-cell="cell">
      <span>{{cell.value.substring(2, 4)}}</span>
    </ng-template>
  </igx-column>

  <igx-column *ngIf="screen.width > 475 && !isTabletPortrait" [width]="'70px'" field="CaseNumber" [header]="'Sak' "
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-column *ngIf="!isDesktopDevice" width="180px" field="CaseDiscipline" header="Fagområde" [sortable]="true"
    [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

  <igx-grid-footer class="grid-footer-container">
    <dlx-grid-item-count [isMobile]="isMobile" [current]="caseSearchGrid.totalRowsCountAfterFilter"
      [total]="cases.length" />
  </igx-grid-footer>
</igx-grid>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="caseSearchGrid" [column]="column"></grid-filter-input>
</ng-template>